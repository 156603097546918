import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import {OverPack} from 'rc-scroll-anim';
import Rivolie from './img/Rivolie.jpeg'
import logosv from './img/Logo Sport Vlaanderen.jpg'
import agradi from './img/Agradi_partnerprogramma_logo_kleur.png'
import kddak from './img/kddak.jpg'
import natuurlijkgoed from "./img/natuurlijk-goed.png"
class Content extends React.PureComponent {
  render() {
    return (
      <div className='home-page-wrapper content0-wrapper' >
        <div className= 'home-page content0'>

          <OverPack  playScale={ 0.3} key='test'>
          <div className="page2-title">
            Sponsors
          </div> 
          <div>
            Volgende partners ondersteunen onze ponywerking en zorgen ervoor dat we kwalitatieve lessen kunnen aan bieden aan onze leden.
          </div>
          <Row style={{margin:"10px"}}>
                        <Col>
                      <div className="page2-subTitle">KD Dak</div>
              <a href="https://kddak.be/" target="_blank"><img src={kddak} alt="KD dak" style={{height:"150px", marginRight:"7px"}}/></a> <br/>
              
Bij <b>KD Dak BV</b> in Nieuwkerken-Waas en Vrasene rekent u op een team van <b>ervaren dakwerkers</b> die actief zijn in het Waasland. Wij voeren alle dakwerken uit, zowel op hellende als op platte daken.<br/>

 

Hierbij zijn we bedreven in uiteenlopende dakbedekkingsmaterialen. Voor platte daken werken we uitsluitend met EPDM omwille van de kwaliteit. Daarnaast werken we uw dakgoot af en voeren zink- en koperwerken uit. Ook contacteert u ons voor kleine herstellingen, zoals het vervangen van enkele pannen of het herstellen van dakgoten. Ten slotte staan we in voor het onderhoud van uw dak, lichtkoepels en dakgoten.
            </Col>
            
            <Col>
              <div className="page2-subTitle">Natuurlijk Goed</div>
              <a href="https://www.natuurlijkgoed.be/" target="_blank"><img src={natuurlijkgoed} alt="agradi" style={{ height:"150px", marginRight:"7px"}}/></a> <br/>
              Onafhankelijk voedingsadvies voor paard en ruiter.
verdeler van natuurlijke voeders, bodemverbeteraars, stalstrooisels en basis benodigdheden voor paard en dier. Ga snal naar <a href="https://www.natuurlijkgoed.be/" target="_blank">Natuurlijk Goed</a>
            </Col>
            
            
            <Col>
              <div className="page2-subTitle">Agradi</div>
              <a href="https://agradi.nl/" target="_blank"><img src={agradi} alt="agradi" style={{ height:"150px", marginRight:"7px"}}/></a> <br/>
              Agradi is dé online winkel voor paarden gerief. Ponylessen 't Perhof is onderdeel van het Agradi Partner programma.  <br/>
              Vraag gerust onze partnercode voor 5% korting. Bestel online op <a href="https://agradi.nl/" target="_blank">Agradi.nl</a>.
            </Col>
            
          </Row>
          </OverPack>
      </div>
    </div>
    );
  }
}

export default Content;
