import React, { useState } from 'react';
import {Modal, Form, Input, Select, Checkbox, DatePicker, TimePicker, Spin, Typography} from 'antd'

import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import AdminLayout from './KalenderLayout'
import { Row, Col } from 'antd';
const { Title, Text} = Typography;

const {Option} = Select
const GET_TOTALEN = 
gql`query($year:Int!){
  
  allLesgeverItems{
    edges{
      node{
        id
        lesgever{
          firstName
        }
        loonPerMaand(year:$year)
        urenPerMaand(year:$year)
        aantalPerMaand(year:$year)
      }
    }
  }
}
`

const LessenPerLesgever = (props)=>{
    const [year,setYear]  =  useState(moment().year())
    const [parameter,setParameter]  =  useState('aantalPerMaand')

    const {data, error, loading}=useQuery(GET_TOTALEN, {variables:{year}})
    const months  =  moment.monthsShort().map((item,i)=>(
      <Col span={1} value={i+1}><Title level={5}>{item}</Title></Col>))
    const header  = <Row>
            <Col span={4}><Title level={5}>Naam</Title></Col>
            {months}
            <Col span={2}><Title level={5}>Totaal</Title></Col>
      </Row>
    let rows = []
    if(!loading){
        var totalRow =[0,0,0,0,0,0,0,0,0,0,0,0]
        rows =  data && data.allLesgeverItems.edges.map(row=>{
             totalRow = totalRow.map((a,i) => a +  row.node[parameter][i])
             const monthData= row.node[parameter].map(item=>(<Col span={1}>{Math.round(item*100)/100}</Col>))
             console.log(row)
             return (
                 <Row>
                      <Col span={4}>{row.node.lesgever.firstName}</Col>
                      {monthData}
                      <Col span={2}><Text strong={true}>{Math.round(row.node[parameter].reduce((a, b) => a + b, 0)*100)/100}</Text></Col>
                </Row>
             
             )
         })    
         console.log(totalRow)
         {rows && rows.push(
                 <Row>
                      <Col span={4}>Total</Col>
                      {totalRow.map(item=>(<Col span={1}>{Math.round(item*100)/100}</Col>))}
                      <Col span={2}><Text strong={true}>{Math.round(totalRow.reduce((a, b) => a + b, 0)*100)/100}</Text></Col>
                </Row>
         
             )}
    }
    console.log(rows)
    return (
        <AdminLayout 
         title="Lessen Per Lesgever" >
         <div style={{'minHeight':'100vh', backgroundColor:'white', padding:'20px'}} >
         <Row>
            <Col span={4}>
                <Select style={{ width: 120 }} value={year} onChange={(value)=>setYear(value)}>
                    <Option value={2025} >2025</Option>

                    <Option value={2024} >2024</Option>

                    <Option value={2023} >2023</Option>
                    <Option value={2022}>2022</Option>
                    <Option value={2021} >2021</Option>

                </Select>
            </Col>
            <Col span={4}>
                <Select style={{ width: 120 }} value={parameter} onChange={(value)=>setParameter(value)}>
                    <Option value='aantalPerMaand'>aantal Lessen</Option>
                    <Option value='urenPerMaand'>Uren</Option>
                    <Option value='loonPerMaand'>Loon</Option>

                </Select>
            </Col>
         </Row>
            {header}
             {loading && <Spin />}
             {rows} 
            </div>
         </AdminLayout>
)}

export default LessenPerLesgever